// core version + navigation, pagination modules:
import Swiper from 'swiper'
import { Navigation } from 'swiper'

export default class MobileSliders {
    constructor(slider, index) {
        this.slider = slider
        this.index = index
        this.slides = Array.from(this.slider.querySelectorAll('.swiper-slide'))

        this.options = this.setOptions()
        this.initSlider()
    }

    setOptions() {
        let options = {}
        options = {
            modules: [Navigation],
            slidesPerView: 1,
            spaceBetween: 20,
            navigation: {
                nextEl: (this.slider.classList.contains('data')) ? ".data-next" : ".structure-next",
                prevEl: (this.slider.classList.contains('data')) ? ".data-prev" : ".structure-prev",
            },
        }

        return options
    }

    initSlider() {

        console.log(this.slider.classList)

        let mobile = window.matchMedia('(min-width: 0px) and (max-width: 768px)');

        console.debug('  -> Sliders init')
        let swiperInstance = new Swiper(this.slider, this.options)
        if(mobile.matches){

            swiperInstance.init()
            this.swiperInstance = swiperInstance
        }else{
            swiperInstance.destroy()
        }
    }

    destroy() {
        console.debug('  -> Sliders destroy')
        this.swiperInstance.destroy()
    }

    static destroyAll() {
        console.debug('  -> Sliders destroy all')
        MobileSliders.items.forEach((slider) => {
            slider.destroy()
        })
    }

    static init() {
        console.debug('== Sliders components ==')
        MobileSliders.items = Array.from(document.querySelectorAll('.swiper-mobile')).map(
            (slider, index) => new MobileSliders(slider, index)
        )
    }
}