// core version + navigation, pagination modules:
import Swiper from 'swiper'
import { Pagination } from 'swiper'

export default class Sliders {
    constructor(slider, index) {
        this.slider = slider
        this.index = index
        this.slides = Array.from(this.slider.querySelectorAll('.swiper-slide'))

        this.options = this.setOptions()
        this.initSlider()
    }

    setOptions() {
        let options = {}
        options = {
            modules: [Pagination],
            slidesPerView: 1,
            spaceBetween: 14,
            pagination: {
                el: '.pagination'
            },
            breakpoints: {
                768:{
                    slidesPerView: 2.1,
                }
            }
        }

        return options
    }

    initSlider() {
        console.debug('  -> Sliders init')
        let swiperInstance = new Swiper(this.slider, this.options)

        swiperInstance.init()
        this.swiperInstance = swiperInstance
    }

    destroy() {
        console.debug('  -> Sliders destroy')
        this.swiperInstance.destroy()
    }

    static destroyAll() {
        console.debug('  -> Sliders destroy all')
        Sliders.items.forEach((slider) => {
            slider.destroy()
        })
    }

    static init() {
        console.debug('== Sliders components ==')
        Sliders.items = Array.from(document.querySelectorAll('.swiper')).map(
            (slider, index) => new Sliders(slider, index)
        )
    }
}