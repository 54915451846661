import Menu from './modules/Menu'
import Sliders from './modules/Sliders'
import SwitchItems from './modules/SwitchItem'
import MobileSliders from './modules/MobileSlider'
import ContactForm from './modules/ContactForm'

// Debug helper
const debug = true

if (!debug) {
  console.log = function () {}
}

export default class Main {
  constructor() {
    this.init()
  }

  init() {

    // run once when page loads
    window.addEventListener('load', () => {
      this.initialize()
    })
  }

  initialize() {
    Menu.init()
    MobileSliders.init()
    Sliders.init()
    SwitchItems.init()
    ContactForm.init()
  }

  destory() {
    
  }
}

const main = new Main()
