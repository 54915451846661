export default class SwitchItems{
    constructor(switchItems, index){
        this.switchItems = switchItems
        this.index = index

        this.listItems = this.switchItems.querySelectorAll('.list-container .list-item')
        this.images = this.switchItems.querySelectorAll('.images picture') 

        this.reset = this.reset.bind(this)

        this.init()
    }

    init(){
        console.log(this.images)
        this.addListeners();
    }

    addListeners(){
        var images = this.images
        this.listItems.forEach((item, index) => {
            item.addEventListener('click', this.reset)
            item.addEventListener('click', function(){
                if(!this.classList.contains('focus')){
                    this.classList.add('focus')
                    images[index].classList.add('focus')
                }
            })
        })
    }

    reset(){
        this.listItems.forEach((item) => {
            item.classList.remove('focus')
        })
        this.images.forEach((image) => {
            image.classList.remove('focus')
        })
    }

    static init(){
        SwitchItems.items = Array.from(
            document.querySelectorAll('.switch-items-container')
          ).map((switchItems, index) => new SwitchItems(switchItems, index))
    }
}