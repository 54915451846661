import JustValidate from 'just-validate'

export default class ContactForm {
    static init() {
        console.log('== Contact Form component ==')

        const form = document.querySelector('#contact-form')
        const formSubmittButton = form.querySelector('.button')

        let postBody = {}

        // init validation
        const validation = new JustValidate('#contact-form')

        validation
            .addField('#name', [
                {
                    rule: 'required',
                    errorMessage: 'Il nome è obbligatorio',
                },
            ])
            .addField('#lastname', [
                {
                    rule: 'required',
                    errorMessage: 'Il cognome è obbligatorio',
                },
            ])
            .addField('#email', [
                {
                    rule: 'required',
                    errorMessage: 'L\'email è obbligatoria',
                },
                {
                    rule: 'email',
                    errorMessage: 'L\'email non è valida',
                },
            ])
            .onSuccess((event) => {
                
                formSubmittButton.classList.add('h-visually-disabled')

                postBody = {
                    name: form.elements.name.value,
                    lastname: form.elements.lastname.value,
                    email: form.elements.email.value,
                    object: form.elements.object.value,
                    message: form.elements.message.value,
                }

                const formSubmissionMsgOK = document.querySelector(
                    '.form-submission-message.success'
                )
                const formSubmissionMsgKO = document.querySelector(
                    '.form-submission-message.error'
                )

                const asyncPostCall = async () => {
                    formSubmissionMsgOK.classList.remove('show')
                    formSubmissionMsgKO.classList.remove('show')

                    try {
                        const response = await fetch('./sendMail.php', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify(postBody),
                        })

                        const result = await response.text()

                        if (result === 'OK') {
                            formSubmissionMsgOK.classList.add('show')
                        } else {
                            formSubmissionMsgKO.classList.add('show')
                        }

                        formSubmittButton.classList.remove('h-visually-disabled')
                    } catch (error) {
                        formSubmissionMsgKO.classList.add('show')
                        formSubmittButton.classList.remove('h-visually-disabled')
                        console.log(error)
                    }
                }

                asyncPostCall()
            })

            console.log(validation)
    }
}
