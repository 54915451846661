export default class Menu{
    constructor(menu, index){
        this.menu = menu
        this.menuHeight = this.menu.offsetHeight
        this.index = index
        this.prevScroll = window.scrollY
        this.showHide = this.showHide.bind(this)

        this.hamburger = this.menu.querySelector('.container .hamburger')
        this.navbar = this.menu.querySelector('.container .navbar')
        this.menuItem = this.navbar.querySelectorAll('a')

        this.openClose = this.openClose.bind(this)

        this.init()
    }

    init(){
        this.addListeners();
    }

    addListeners(){
        window.addEventListener('scroll', this.showHide)
        this.hamburger.addEventListener('click', this.openClose)

        this.menuItem.forEach((item) => {
            item.addEventListener('click', () => {
                    document.querySelector('header').classList.remove('open')
                    document.querySelector('html').style.overflowY = 'inherit'
            })
        })
    }

    showHide(){
        var currentScroll = window.scrollY
        if(currentScroll > this.prevScroll && currentScroll > this.menuHeight){
            this.menu.style.top = - this.menuHeight + 'px'
        }if(currentScroll <= this.prevScroll){
            this.menu.style.top = 0
        }
        this.prevScroll = currentScroll
    }

    openClose(){
        if(!this.menu.classList.contains('open')){
            this.menu.classList.add('open')
            document.querySelector('html').style.overflowY = 'hidden'
        }else{
            this.menu.classList.remove('open')
            document.querySelector('html').style.overflowY = 'inherit'
        }
    }

    static init(){
        Menu.items = Array.from(
            document.querySelectorAll('header')
          ).map((menu, index) => new Menu(menu, index))
    }
}